.App {
    text-align: center;
    width: 100%;
    height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 7px;

}
 
::-webkit-scrollbar-thumb {
    background: #3b0764;
    border: 2px solid #8350DF;
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}