.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust this if you want the container to be centered within a specific height */
}

.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 800px;
    max-width: 100%;
    min-height: 550px;
    margin-top: -1%;
    margin-left: -1%;
}

.log-in,
.sign-up {
    position: absolute;
    top: 0;
    left: 0;
    height: 115%;
    transition: all 0.6s ease-in-out;
}

.log-in {
    width: 50%;
    opacity: 0;
    z-index: 1
}

.sign-up {
    width: 50%;
    z-index: 2;
}

.form {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    height: 130%;
}

button {
    transition: transform 80ms ease-in;
}

button:active {
    transform: scale(0.90);
}

.container.right-panel-active .sign-up {
    transform: translateX(100%);
}

.container.right-panel-active .log-in {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 150%;
    /* slider size setter */
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    position: relative;
    color: #fff;
    left: -100%;
    height: 100%;
    width: 200%;
    background: black;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-left,
.overlay-right {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    top: 0;
    height: 100%;
    width: 50%;
    /* transform: translateX(0); */
    transition: transform 0.6s ease-in-out;
    font-size: 24px;
}

.overlay-left {
    transform: translateX(-20%);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

@media (max-width: 768px) {
    .container {
        width: 370px;
    }
}